import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';

const ManagerDetail = () => {
    const teamImage = (url) => {
        return `${process.env.REACT_APP_BACKEND_URL}/public/${url}`
    };

    const [manager, setManager] = useState(null);
    const [teams, setTeams] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamData, setTeamData] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const managerId = queryParams.get('id');

    useEffect(() => {
        const fetchManager = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_MANAGER_DETAILS_ENDPOINT}?id=${managerId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response, "rrr")
                setManager(response.data.data.managerDetails);
                setTeams(response.data.data.teamNames);

            } catch (error) {
                console.error('Failed to fetch manager details');
            }
        }
        if (managerId) {
            fetchManager();
        } else {
            console.error('No manager ID provided');
        }
    }, [managerId]);

    const handleTeamClick = async (teamName) => {
        setSelectedTeam(teamName);

        try {
            const token = localStorage.getItem('authToken');
            const url = new URL(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_MANAGER_DETAILS_ENDPOINT}`);
            url.searchParams.set("id", managerId);
            url.searchParams.set("teamName", teamName);

            const response = await axiosInstance.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(response.data.data.teamStats, "teamstats")
            setTeamData(response.data.data.teamStats);

        } catch (error) {
            console.error('Failed to fetch team statistics');
        }
    };

    return (
        <div className='maanger-detail'>
            <div className="content-wrapper">
                <section className="main-section coach-section spacer">
                    <div className="container">
                        <div className="wrapper-card">
                            <div className="inner-bg-card">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row justify-content-start gy-4">
                                            <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="info-detail">
                                                    <h2 className="h4">Name</h2>
                                                    <p className="mb-0">{manager?.username}</p>
                                                </div>
                                            </div>
                                            {/* <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="info-detail">
                                                    <h2 className="h4">Last name</h2>
                                                    <p className="mb-0">Golding</p>
                                                </div>
                                            </div> */}
                                            <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="info-detail">
                                                    <h2 className="h4">Country</h2>
                                                    <p className="mb-0">{manager?.country}</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="info-detail">
                                                    <h2 className="h4">Email address</h2>
                                                    <p className="mb-0">{manager?.email}</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="info-detail">
                                                    <h2 className="h4">Gender</h2>
                                                    <p className="mb-0">{manager?.gender}</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="info-detail">
                                                    <h2 className="h4">Mobile number</h2>
                                                    <p className="mb-0">{manager?.phoneNumber}</p>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-lg-4 col-xl-3">
                                                <div className="info-detail">
                                                    <h2 className="h4">Status</h2>
                                                    <p className="mb-0">{manager?.status}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-card">
                            <div className="row gy-5">
                                {/* Teams section */}
                                <div className="col-xl-12">
                                    <div className="common-sub-heading">
                                        <h2>Team Added by Manager</h2>
                                    </div>
                                    <div className="added-team-list">
                                        <div className="row g-3">
                                            {teams && teams.length > 0 && teams.map((team) => (
                                                <div
                                                    key={team.name}
                                                    className="col-12 col-sm-6 col-xl-4"
                                                    onClick={() => handleTeamClick(team.name)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <div className="team-card">
                                                        <div className="team-img">
                                                            <img src={team.badge ? teamImage(team.badge) : '/assets/images/zlatan.jpg'} alt={team.name} />
                                                        </div>
                                                        <h3>{team.name}</h3>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {/* Team statistics section */}
                                {selectedTeam && (
                                    <>
                                        <div className="col-xl-6">
                                            <div className="common-sub-heading">
                                                <h2>{selectedTeam} Statistics</h2>
                                            </div>
                                            <div className="team-statistics">
                                                <div className="row g-3">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Total Game Played</h3>
                                                                <h4>{teamData?.totalGames}</h4>
                                                            </div>
                                                            <div className="statistics-info">
                                                                <h3>Won</h3>
                                                                <h4>{teamData?.wins}</h4>
                                                            </div>
                                                            <div className="statistics-info">
                                                                <h3>Lost</h3>
                                                                <h4>{teamData?.losses}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Total Minutes Played</h3>
                                                                <h4>{teamData?.totalMinutesPlayed}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Total Goal Assists</h3>
                                                                <h4>{teamData?.totalAssists}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Total Goals Scored</h3>
                                                                <h4>{teamData?.totalGoals}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Total Goal Conceded</h3>
                                                                <h4>{teamData?.totalGoalsConceded}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="success-card">
                                                            <div className="success-content">
                                                                <div className="success-info">
                                                                    <h3>1024</h3>
                                                                    <h4>Total Passes</h4>
                                                                </div>
                                                                <div className="success-info">
                                                                    <h3>552</h3>
                                                                    <h4>Total Passes</h4>
                                                                </div>
                                                            </div>
                                                            <div className="success-graph">
                                                                <div className="success-graph-blk">
                                                                    <img src="/assets/images/progress-wheel.png" alt="graph" />
                                                                </div>
                                                                <h4>Success rate</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="card-flex-content">
                                                            <h2>how scrored</h2>
                                                            <div className="statistics-card">
                                                                <div className="statistics-info">
                                                                    <h3>Left foot</h3>
                                                                    <h4>{teamData?.howScored?.['Left Foot']}</h4>
                                                                </div>
                                                                <div className="statistics-info">
                                                                    <h3>Right foot</h3>
                                                                    <h4>{teamData?.howScored?.['Right Foot']}</h4>
                                                                </div>
                                                                <div className="statistics-info">
                                                                    <h3>Head</h3>
                                                                    <h4>{teamData?.howScored?.['Headed']}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="card-flex-content">
                                                            <h2>Bookings</h2>
                                                            <div className="statistics-card">
                                                                <div className="statistics-info">
                                                                    <h3>Yellow Card</h3>
                                                                    <div className="match-card">
                                                                        <div className="card-type yellow-card" />
                                                                        <div className="match-card-count">{teamData?.bookings?.find(b => b.cardName === "Yellow Card")?.count}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="statistics-info">
                                                                    <h3>Red Card</h3>
                                                                    <div className="match-card">
                                                                        <div className="card-type red-card" />
                                                                        <div className="match-card-count">{teamData?.bookings?.find(b => b.cardName === "Red Card")?.count}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {/* Players List Section */}
                                        <div className="col-xl-6">
                                            <div className="common-sub-heading">
                                                <h2>Players list</h2>
                                            </div>
                                            <div className="player-list">
                                                {teamData?.players && teamData?.players?.length > 0 ? teamData?.players?.map((player) => (
                                                    <div className="player-card" key={player.name}>
                                                        <div className="player-stats-card">

                                                            <div className="player-img">
                                                                <img src="/assets/images/ronaldo.jpg" alt="Player" />
                                                            </div>
                                                            <div className="player-info">
                                                                <h3>{player?.name}</h3>
                                                                <p className="goal-count">{player?.squad}</p>
                                                                <p className="goal-by-foot">{player?.preferredFoot}</p>
                                                            </div>
                                                        </div>

                                                        <div className="player-team-role">
                                                            <div className="player-team-info">
                                                                <div className="player-team-img">
                                                                    <img src={teamImage(player?.teamBadge) || '/assets/images/manchester-logo.svg'} alt={player?.teamName} />
                                                                </div>
                                                                <h3>{player?.teamName || ''}</h3>
                                                            </div>
                                                            <p className="player-role">{player?.position}</p>
                                                        </div>

                                                    </div>
                                                )) : <div>No players yet</div>}
                                            </div>
                                        </div>
                                    </>
                                )}


                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    )
}

export default ManagerDetail