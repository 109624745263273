import React, { useEffect, useState, useCallback } from 'react';
import axiosInstance from '../api/axiosInstance';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const Dashboard = () => {
    const defaultData = [];

    const [overallData, setOverallData] = useState(null);
    const [playerData, setPlayerData] = useState(defaultData);
    const [agentData, setAgentData] = useState(defaultData);
    const [managerData, setManagerData] = useState(defaultData);
    const [supporterData, setSupporterData] = useState(defaultData);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('authToken');

            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_DASHBOARD_API}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                console.log(response.data.data, "Fetched Data");
                setOverallData(response.data.data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);

    const formatDate = useCallback((dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options);
    }, []);

    const sortByDate = useCallback((data) => {
        return data.slice().sort((a, b) => new Date(a.date) - new Date(b.date));
    }, []);

    useEffect(() => {
        if (overallData && overallData.last7DaysUsersByRole) {
            const sortedStats = sortByDate(overallData.last7DaysUsersByRole || []);
            console.log("Sorted Stats:", sortedStats);

            const playerDataPoints = sortedStats.map((stat) => ({
                date: formatDate(stat.date),
                value: stat.statsByRole?.Player || 0
            }));

            const agentDataPoints = sortedStats.map((stat) => ({
                date: formatDate(stat.date),
                value: stat.statsByRole?.Agent || 0
            }));

            const managerDataPoints = sortedStats.map((stat) => ({
                date: formatDate(stat.date),
                value: stat.statsByRole?.Manager || 0
            }));

            const supporterDataPoints = sortedStats.map((stat) => ({
                date: formatDate(stat.date),
                value: stat.statsByRole?.Supporter || 0
            }));
            console.log("Sorted playerDataPoints:", playerDataPoints);
            setPlayerData(playerDataPoints);
            setAgentData(agentDataPoints);
            setManagerData(managerDataPoints);
            setSupporterData(supporterDataPoints);
        }
    }, [overallData, formatDate, sortByDate]);


    const calculateProgress = (thisMonth, totalMonth) => {
        return totalMonth === 0 ? 0 : Math.min((thisMonth / totalMonth) * 100, 100);
    };

    const progressData = [
        {
            label: 'Number of Registered Players',
            value: {
                thisMonth: overallData?.currentMonthUsersByRoleMap?.Player || 0,
                totalMonth: overallData?.totalUsersByRole?.find(role => role._id === 'Player')?.totalCount || 0
            }
        },
        {
            label: 'Number of Registered Agents',
            value: {
                thisMonth: overallData?.currentMonthUsersByRoleMap?.Agent || 0,
                totalMonth: overallData?.totalUsersByRole?.find(role => role._id === 'Agent')?.totalCount || 0
            }
        },
        {
            label: 'Number of Registered Managers',
            value: {
                thisMonth: overallData?.currentMonthUsersByRoleMap?.Manager || 0,
                totalMonth: overallData?.totalUsersByRole?.find(role => role._id === 'Manager')?.totalCount || 0
            }
        },
        {
            label: 'Number of Registered Supporters',
            value: {
                thisMonth: overallData?.currentMonthUsersByRoleMap?.Supporter || 0,
                totalMonth: overallData?.totalUsersByRole?.find(role => role._id === 'Supporter')?.totalCount || 0
            }
        },
        {
            label: 'Number of Total Teams Created',
            value: {
                thisMonth: overallData?.totalTeamsThisMonth || 0,
                totalMonth: overallData?.totalTeams || 0
            }
        },
        {
            label: 'Total Revenue Generated',
            value: {
                thisMonth: overallData?.totalRevenueThisMonth || 0,
                totalMonth: overallData?.totalRevenue || 0
            }
        }
    ];

    return (
        <section className="main-section hero-section spacer">
            <div className="container">
                <div className="row gy-3">
                    <div className="col-lg-12">
                        <div className="dashboard-blk">
                            <div className="total-card-blk">
                                <div className="row g-3">
                                    {progressData.map((item, index) => (
                                        <div key={index} className="col-12 col-sm-6 col-xl-4">
                                            <div className="total-card-inner">
                                                <div className="d-flex justify-content-between align-items-start gap-3">
                                                    <h2>{item.label}</h2>
                                                    <a href="javascript:void(0)" className="viewbtn">View</a>
                                                </div>
                                                <div className="card-progress-blk">
                                                    <div className="progress-blk">
                                                        <div className="d-flex justify-content-between">
                                                            <h3>This month</h3>
                                                            <span className="total-value">{item?.value?.thisMonth}</span>
                                                        </div>
                                                        <div className="progress" role="progressbar">
                                                            <div className="progress-bar bg-danger" style={{ width: `${calculateProgress(item?.value?.thisMonth, 1000)}%` }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="progress-blk">
                                                        <div className="d-flex justify-content-between">
                                                            <h3>Total month</h3>
                                                            <span className="total-value">{item?.value?.totalMonth}</span>
                                                        </div>
                                                        <div className="progress" role="progressbar">
                                                            <div className="progress-bar" style={{ width: `${calculateProgress(item?.value?.thisMonth, item?.value?.totalMonth)}%` }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* 7 Days Statistics Graphs */}
                            <div className="row mt-4 g-4">
                                {playerData.length && agentData.length && supporterData.length && managerData.length ? (
                                    <>
                                        <div className="col-xl-6">
                                            <h3>Player Data Over the Last 7 Days</h3>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <BarChart data={playerData}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="date" interval={0} />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="value" fill="#0d6efd" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>

                                        <div className="col-xl-6">
                                            <h3>Agents Data Over the Last 7 Days</h3>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <BarChart data={agentData}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="date" interval={0} />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="value" fill="#de3048" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>

                                        <div className="col-xl-6">
                                            <h3>Supporters Data Over the Last 7 Days</h3>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <BarChart data={supporterData}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="date" interval={0} />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="value" fill="#de3048" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>

                                        <div className="col-xl-6">
                                            <h3>Managers Data Over the Last 7 Days</h3>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <BarChart data={managerData}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="date" interval={0} />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="value" fill="#0d6efd" />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </>
                                ) : (
                                    <div>No data available for chart visualization</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;
