import React, { useState, useEffect } from 'react';
import { useProfileNameContext } from '../context/ProfileNameContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { formatDate } from '../api/FormatDate';
import Select from 'react-select';

const AgentDetail = () => {

    const [agent, setAgent] = useState(null);
    const [players, setPlayers] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [playerDetails, setPlayerDetails] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedSeason, setSelectedSeason] = useState('');
    const [filteredGames, setFilteredGames] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const { setName } = useProfileNameContext();
    const queryParams = new URLSearchParams(location.search);

    const agentId = queryParams.get('id');

    const teamImage = (url) => {
        return `${process.env.REACT_APP_BACKEND_URL}/public/${url}`
    };

    useEffect(() => {
        const fetchAgent = async () => {

            try {
                const token = localStorage.getItem('authToken');
                const response = await axiosInstance.get(`${process.env.REACT_APP_AGENT_DETAILS_ENDPOINT}?id=${agentId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setAgent(response.data.data.agentDetails);
                setName(response.data.data.agentDetails.username);

                const playerData = response.data.data.players || [];

                const formattedPlayers = playerData.map(player => ({
                    value: player._id,
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={player?.profileImage ? player?.profileImage : '/assets/images/dummy-user.png'} alt={player.name} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                            {player.name}
                        </div>
                    )
                }));

                setPlayers([{ value: '', label: 'Select Player' }, ...formattedPlayers])
            } catch (error) {
                console.error('Failed to fetch agent details');
            }
        };

        if (agentId) {
            fetchAgent();
        } else {
            console.error('No agent ID provided');
        }
    }, [agentId]);

    useEffect(() => {
        const fetchPlayerDetails = async () => {
            if (selectedPlayer && selectedPlayer.value) {
                try {
                    const token = localStorage.getItem('authToken');
                    const response = await axiosInstance.get(`${process.env.REACT_APP_AGENT_DETAILS_ENDPOINT}?id=${agentId}&playerId=${selectedPlayer.value}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setPlayerDetails(response.data.data.playerDetail);
                } catch (err) {
                    console.error('Failed to fetch player details');
                }
            } else {
                // Clear player details and filtered games if no player is selected
                setPlayerDetails(null);
                setFilteredGames([]);
            }
        };

        fetchPlayerDetails();
    }, [selectedPlayer, agentId]);

    console.log(playerDetails, "pppp")
    const handleChange = (selectedOption) => {
        setSelectedPlayer(selectedOption);
        navigate(`?id=${agentId}&playerId=${selectedOption.value}`);
    };


    const handleTeamChange = (e) => {
        setSelectedTeam(e.target.value);
    };

    const handleSeasonChange = (e) => {
        setSelectedSeason(e.target.value);
    };
    useEffect(() => {
        if (selectedTeam && selectedSeason && playerDetails?.gameList) {
            const filtered = playerDetails.gameList.filter((game) => {
                const teamMatch = game.teams.some((team) => team.name === selectedTeam);
                const seasonMatch = game.seasonId === selectedSeason;
                return teamMatch && seasonMatch;
            });
            setFilteredGames(filtered);
        } else if (playerDetails?.gameList) {
            // If no filter is selected, show all games
            setFilteredGames(playerDetails.gameList);
        }
    }, [selectedTeam, selectedSeason, playerDetails]);

    return (
        <div className="content-wrapper">
            <section className="main-section coach-section spacer">
                <div className="container">
                    <div className="wrapper-card">
                        <div className="row gx-lg-5 gy-3">
                            <div className="col-xl-12">
                                <div className="common-sub-heading">
                                    <h2>{`${agent?.username} Details`}</h2>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="supporter-statistics bg-white p-0">
                                    <div className="common-info-detail">
                                        <h2 className="h4">User name</h2>
                                        <p className="mb-0">{agent?.username}</p>
                                    </div>
                                    <div className="common-info-detail">
                                        <h2 className="h4">Mobile number</h2>
                                        <p className="mb-0">{agent?.phoneNumber}</p>
                                    </div>
                                    <div className="common-info-detail">
                                        <h2 className="h4">DOB</h2>
                                        <p className="mb-0">{formatDate(agent?.dob)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="supporter-statistics bg-white p-0">
                                    <div className="common-info-detail">
                                        <h2 className="h4">Email address</h2>
                                        <p className="mb-0">{agent?.email}</p>
                                    </div>
                                    <div className="common-info-detail">
                                        <h2 className="h4">Gender</h2>
                                        <p className="mb-0">{agent?.gender}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='select-player-blk'>
                        <Select
                            value={selectedPlayer}
                            onChange={handleChange}
                            options={players}
                            isSearchable={false}
                            placeholder="Select a player"
                        />
                    </div>
                    {playerDetails && (
                        <>
                            <div className="wrapper-card">
                                <div className="row align-items-center gy-5">
                                    <div className="col-lg-2">
                                        <div className="player-img-blk">
                                            <img src={playerDetails?.profileImage ? playerDetails?.profileImage : '/assets/images/dummy-user.png'} alt={playerDetails?.name} />
                                        </div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="playerinfo-div">
                                            <div className="row gy-3">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="supporter-statistics bg-white">
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Name</h2>
                                                            <p className="mb-0">{playerDetails?.name}</p>
                                                        </div>
                                                        {/* <div className="common-info-detail">
                                                            <h2 className="h4">Last name</h2>
                                                            <p className="mb-0">Golding</p>
                                                        </div> */}
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Country</h2>
                                                            <p className="mb-0">{playerDetails?.country}</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Email address</h2>
                                                            <p className="mb-0">{playerDetails?.email}</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Gender</h2>
                                                            <p className="mb-0">{playerDetails?.gender}</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Phone number</h2>
                                                            <p className="mb-0">{playerDetails?.phoneNumber}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="supporter-statistics bg-white">
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Address</h2>
                                                            <p className="mb-0">#123 street 23 new york</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">DOB</h2>
                                                            <p className="mb-0">{playerDetails?.dob}</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Age</h2>
                                                            <p className="mb-0">{playerDetails.age}</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Position</h2>
                                                            <p className="mb-0">{playerDetails?.position}</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Selected Foot</h2>
                                                            <p className="mb-0">{playerDetails?.preferredFoot}</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Nationality</h2>
                                                            <p className="mb-0">{playerDetails?.country}</p>
                                                        </div>
                                                        <div className="common-info-detail">
                                                            <h2 className="h4">Status</h2>
                                                            <p className="mb-0">{playerDetails?.status}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-card">
                                <div className="row g-3">
                                    <div className="col-xl-6">
                                        <div className="common-sub-heading">
                                            <h2>Match List</h2>
                                        </div>
                                        <div className="select-team-statistics">
                                            <div className="form-block">
                                                <select className="form-select" onChange={handleTeamChange}>
                                                    <option value="">Stats of Teams</option>
                                                    {playerDetails?.teamList?.map((team, index) => (
                                                        <option key={index} value={team.name}>
                                                            {team.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-block">
                                                <select className="form-select" onChange={handleSeasonChange}>
                                                    <option value="">Stats of Seasons</option>
                                                    {playerDetails?.seasonList?.map((season, index) => (
                                                        <option key={index} value={season._id}>
                                                            {season.from}-{season.to}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="team-statistics">
                                            <div className="team-result-card-list">
                                                {filteredGames.length > 0 ? (
                                                    filteredGames.map((game, index) => {
                                                        // Determine which team is 'Self' and which is 'Opponent' based on the 'type' field
                                                        const selfTeam = game.teams.find(team => team.type === "Self");
                                                        const opponentTeam = game.teams.find(team => team.type === "Opponent");

                                                        return (
                                                            <a key={index} href="teams-match-detail.html" className="team-result-card">
                                                                {/* Self Team on the Left */}
                                                                <div className="team-left-card">
                                                                    <div className="team-img">
                                                                        <img src={selfTeam.badge ? teamImage(selfTeam.badge) : '/assets/images/zlatan.jpg'} alt={selfTeam.name} />
                                                                    </div>
                                                                    <h3>{selfTeam.name}</h3>
                                                                </div>

                                                                {/* Match Score */}
                                                                <div className="result-stat">
                                                                    <p className="result-text">
                                                                        <span className="score lead-score">{selfTeam.score}</span>-
                                                                        <span className="score trail-score">{opponentTeam.score}</span>
                                                                    </p>
                                                                </div>

                                                                {/* Opponent Team on the Right */}
                                                                <div className="team-right-card">
                                                                    <div className="team-img">
                                                                        <img src={opponentTeam.badge ? teamImage(opponentTeam.badge) : '/assets/images/zlatan.jpg'} alt={opponentTeam.name} />
                                                                    </div>
                                                                    <h3>{opponentTeam.name}</h3>
                                                                </div>
                                                            </a>
                                                        );
                                                    })
                                                ) : (
                                                    <p style={{ textAlign: 'center' }}>No Data to Display</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-card">
                                <div className="row g-3">
                                    <div className="col-xl-6">
                                        <div className="team-statistics">
                                            <div className="row g-3">
                                                <div className="col-12 col-sm-12">
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>Total Game</h3>
                                                            <h4>{playerDetails?.overall?.totalGame}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Won</h3>
                                                            <h4>{playerDetails?.overall?.wins}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Lost</h3>
                                                            <h4>{playerDetails?.overall?.loss}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <h2 className="h6">Game Type Played</h2>
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>League</h3>
                                                            <h4>{playerDetails?.overall?.gameType?.league}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Cup</h3>
                                                            <h4>{playerDetails?.overall?.gameType?.friendly}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Tournament</h3>
                                                            <h4>{playerDetails?.overall?.gameType?.tournament}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <h2 className="h6">Stages</h2>
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>Final</h3>
                                                            <h4>3</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Semi final</h3>
                                                            <h4>2</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card-flex-content">
                                                        <h2>Match location</h2>
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Home</h3>
                                                                <h4 className="text-center">{playerDetails?.overall?.matchLocation?.home}</h4>
                                                            </div>
                                                            <div className="statistics-info">
                                                                <h3>Away</h3>
                                                                <h4>{playerDetails?.overall?.matchLocation?.away}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="team-statistics">
                                            <div className="row g-3">
                                                <div className="col-12 col-sm-6">
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>Total Minutes Played</h3>
                                                            <h4>{playerDetails?.overall?.timePlayed}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>Total Goal Assists</h3>
                                                            <h4>{playerDetails?.overall?.assists}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <div className="statistics-card">
                                                        <div className="positions-stat statistics-info gap-3">
                                                            <h3 className="fw-bold fs-5">Positions</h3>
                                                            <div className="d-flex justify-content-between w-100">
                                                                <h3>Attacker</h3>
                                                                <h4>{playerDetails?.overall?.positions?.attacker}</h4>
                                                            </div>
                                                            <div className="d-flex justify-content-between w-100">
                                                                <h3>Defender</h3>
                                                                <h4>{playerDetails?.overall?.positions?.defender}</h4>
                                                            </div>
                                                            <div className="d-flex justify-content-between w-100">
                                                                <h3>Goalkeeper</h3>
                                                                <h4>{playerDetails?.overall?.positions?.goalkeeper}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3 className="fw-bold fs-5">Man of the match</h3>
                                                            <h4>{playerDetails?.overall?.playerOfTheMatch}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3 className="fw-bold fs-5">Captain</h3>
                                                            <h4>{playerDetails?.overall?.captain}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card-flex-content">
                                                        <h2>Bookings</h2>
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Yellow Card</h3>
                                                                <div className="match-card">
                                                                    <div className="card-type yellow-card" />
                                                                    <div className="match-card-count">{playerDetails?.overall?.bookings?.yellowCards}</div>
                                                                </div>
                                                            </div>
                                                            <div className="statistics-info">
                                                                <h3>Red Card</h3>
                                                                <div className="match-card">
                                                                    <div className="card-type red-card" />
                                                                    <div className="match-card-count">{playerDetails?.overall?.bookings?.redCards}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-card">
                                <div className="row g-3">
                                    <div className="col-xl-6">
                                        <div className="team-statistics">
                                            <div className="bg-white-statistics">
                                                <div className="row g-3">
                                                    <div className="col-12">
                                                        <div className="top-heading d-flex justify-content-between mb-2">
                                                            <h2>Total Goals Scored </h2>
                                                            <h3>{playerDetails?.overall?.totalGoalsScored}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <h2 className="h6 fw-bold">How Scored</h2>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="how-score-blk">
                                                            <div className="how-score-icon">
                                                                <img src="/assets/images/right-foot.png" alt="Foot" />
                                                            </div>
                                                            <div className="how-score-info">
                                                                <h3>{playerDetails?.overall?.howScored?.rightFootCount}</h3>
                                                                <p>Right foot</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="how-score-blk">
                                                            <div className="how-score-icon">
                                                                <img src="/assets/images/left-foot.png" alt="Foot" />
                                                            </div>
                                                            <div className="how-score-info">
                                                                <h3>{playerDetails?.overall?.howScored?.leftFootCount}</h3>
                                                                <p>Left foot</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="how-score-blk">
                                                            <div className="how-score-icon">
                                                                <img src="/assets/images/head.png" alt="Head" />
                                                            </div>
                                                            <div className="how-score-info">
                                                                <h3>{playerDetails?.overall?.howScored?.headedCount}</h3>
                                                                <p>Head</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="how-score-blk">
                                                            <div className="how-score-icon">
                                                                <img src="/assets/images/other.png" alt="By Other" />
                                                            </div>
                                                            <div className="how-score-info">
                                                                <h3>{playerDetails?.overall?.howScored?.otherCount}</h3>
                                                                <p>Other</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="team-statistics">
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                        <h2>Total Goals Conceded</h2>
                                                        <h3>{playerDetails?.overall?.totalGoalsConceded}</h3>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <h2 className="h6">How Conceded</h2>
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>From Penalty</h3>
                                                            <h4>{playerDetails?.overall?.howConceded?.penalty}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Free kick direct</h3>
                                                            <h4>{playerDetails?.overall?.howConceded?.directFreeKick}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Open play</h3>
                                                            <h4>{playerDetails?.overall?.howConceded?.openPlay}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card-flex-content">
                                                        <h2>Where From</h2>
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Inside the box</h3>
                                                                <h4 className="text-center">{playerDetails?.overall?.whereConceded?.insideTheBoxCount}</h4>
                                                            </div>
                                                            <div className="statistics-info">
                                                                <h3>Outside the box</h3>
                                                                <h4>{playerDetails?.overall?.whereConceded?.outsideTheBoxCount}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card-flex-content">
                                                        <h2>Goals Saved</h2>
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Penalty</h3>
                                                                <h4 className="text-center">{playerDetails?.overall?.goalsSaved?.penalty}</h4>
                                                            </div>
                                                            <div className="statistics-info">
                                                                <h3>Open play</h3>
                                                                <h4>{playerDetails?.overall?.goalsSaved?.openPlay}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}


                </div >
            </section >
        </div >


    )
}

export default AgentDetail