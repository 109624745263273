import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { formatDate } from '../api/FormatDate';
import { useProfileNameContext } from '../context/ProfileNameContext';

const PlayerDetail = () => {
    const [player, setPlayer] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedSeason, setSelectedSeason] = useState('');
    const [filteredGames, setFilteredGames] = useState([]);

    const location = useLocation();
    const { setName } = useProfileNameContext();
    const queryParams = new URLSearchParams(location.search);

    const playerId = queryParams.get('id');

    const teamImage = (url) => {
        return `${process.env.REACT_APP_BACKEND_URL}/public/${url}`
    };

    useEffect(() => {
        const fetchPlayer = async () => {

            try {
                const token = localStorage.getItem('authToken');
                const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_USER_DETAILS_ENDPOINT}?id=${playerId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response.data.data, "plauers data ");
                setPlayer(response.data.data);

                setName(response.data.data.username)
            } catch (error) {
                console.error('Failed to fetch player details');
            }
        };

        if (playerId) {
            fetchPlayer();
        } else {
            console.error('No player ID provided');
        }
    }, [playerId]);

    const handleTeamChange = (e) => {
        setSelectedTeam(e.target.value);
    };

    const handleSeasonChange = (e) => {
        setSelectedSeason(e.target.value);
    };
    useEffect(() => {
        if (selectedTeam && selectedSeason && player?.gameList) {
            const filtered = player.gameList.filter((game) => {
                const teamMatch = game.teams.some((team) => team.name === selectedTeam);
                const seasonMatch = game.seasonId === selectedSeason;
                return teamMatch && seasonMatch;
            });
            setFilteredGames(filtered);
        } else if (player?.gameList) {
            // If no filter is selected, show all games
            setFilteredGames(player.gameList);
        }
    }, [selectedTeam, selectedSeason, player]);

    return (
        <div className='player-detail' >
            <div className="content-wrapper">
                <section className="main-section coach-section spacer">
                    <div className="container">
                        <div className="wrapper-card">
                            <div className="row align-items-center gy-5">
                                <div className="col-lg-2">
                                    <div className="player-img-blk">
                                        <img src={player?.profileImage ? teamImage(player?.profileImage) : '/assets/images/dummy-user.png'} alt="player" />
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="playerinfo-div">
                                        <div className="row gy-3">
                                            <div className="col-md-6 col-lg-6">
                                                <div className="supporter-statistics bg-white">
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Name</h2>
                                                        <p className="mb-0">{player?.username || 'No username available'}</p>
                                                    </div>
                                                    {/* <div className="common-info-detail">
                                                        <h2 className="h4">Last name</h2>
                                                        <p className="mb-0">Golding</p>
                                                    </div> */}
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Country</h2>
                                                        <p className="mb-0">{player?.country}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Email address</h2>
                                                        <p className="mb-0">{player?.email}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Gender</h2>
                                                        <p className="mb-0">{player?.gender}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Phone number</h2>
                                                        <p className="mb-0">{player?.phoneNumber}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="supporter-statistics bg-white">
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Address</h2>
                                                        <p className="mb-0">{player?.town},{player?.country}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">DOB</h2>
                                                        <p className="mb-0">{formatDate(player?.dob)}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Age</h2>
                                                        <p className="mb-0">{player?.age}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Position</h2>
                                                        <p className="mb-0">{player?.position}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Selected Foot</h2>
                                                        <p className="mb-0">{player?.preferredFoot}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Nationality</h2>
                                                        <p className="mb-0">{player?.country}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Status</h2>
                                                        <p className="mb-0">Suspended</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="wrapper-card">
                            <div className="row g-3">
                                <div className="col-xl-6">
                                    <div className="common-sub-heading">
                                        <h2>Match List</h2>
                                    </div>
                                    <div className="select-team-statistics">
                                        <div className="form-block">
                                            <select className="form-select" onChange={handleTeamChange}>
                                                <option value="">Stats of Teams</option>
                                                {player?.teamList?.map((team, index) => (
                                                    <option key={index} value={team.name}>
                                                        {team.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-block">
                                            <select className="form-select" onChange={handleSeasonChange}>
                                                <option value="">Stats of Seasons</option>
                                                {player?.seasonList?.map((season, index) => (
                                                    <option key={index} value={season._id}>
                                                        {season.from}-{season.to}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="team-statistics">
                                        <div className="team-result-card-list">
                                            {filteredGames.length > 0 ? (
                                                filteredGames.map((game, index) => {
                                                    // Determine which team is 'Self' and which is 'Opponent' based on the 'type' field
                                                    const selfTeam = game.teams.find(team => team.type === "Self");
                                                    const opponentTeam = game.teams.find(team => team.type === "Opponent");

                                                    return (
                                                        <a key={index} href="teams-match-detail.html" className="team-result-card">
                                                            {/* Self Team on the Left */}
                                                            <div className="team-left-card">
                                                                <div className="team-img">
                                                                    <img src={selfTeam?.badge ? teamImage(selfTeam?.badge) : '/assets/images/zlatan.jpg'} alt={selfTeam?.name} />
                                                                </div>
                                                                <h3>{selfTeam.name}</h3>
                                                            </div>

                                                            {/* Match Score */}
                                                            <div className="result-stat">
                                                                <p className="result-text">
                                                                    <span className="score lead-score">{selfTeam?.score}</span>-
                                                                    <span className="score trail-score">{opponentTeam?.score}</span>
                                                                </p>
                                                            </div>

                                                            {/* Opponent Team on the Right */}
                                                            <div className="team-right-card">
                                                                <div className="team-img">
                                                                    <img src={opponentTeam.badge ? teamImage(opponentTeam?.badge) : '/assets/images/zlatan.jpg'} alt={opponentTeam?.name} />
                                                                </div>
                                                                <h3>{opponentTeam?.name}</h3>
                                                            </div>
                                                        </a>
                                                    );
                                                })
                                            ) : (
                                                <p style={{ textAlign: 'center' }}>No Data to Display</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="wrapper-card">
                            <div className="row g-3">
                                <div className="col-xl-6">
                                    <div className="team-statistics">
                                        <div className="row g-3">
                                            <div className="col-12 col-sm-12">
                                                <div className="statistics-card">
                                                    <div className="statistics-info">
                                                        <h3>Total Game</h3>
                                                        <h4>{player?.overall?.totalGame}</h4>
                                                    </div>
                                                    <div className="statistics-info">
                                                        <h3>Won</h3>
                                                        <h4>{player?.overall?.wins}</h4>
                                                    </div>
                                                    <div className="statistics-info">
                                                        <h3>Lost</h3>
                                                        <h4>{player?.overall?.loss}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <h2 className="h6">Game Type Played</h2>
                                                <div className="statistics-card">
                                                    <div className="statistics-info">
                                                        <h3>League</h3>
                                                        <h4>{player?.overall?.gameType?.league}</h4>
                                                    </div>
                                                    <div className="statistics-info">
                                                        <h3>Cup</h3>
                                                        <h4>{player?.overall?.gameType?.friendly}</h4>
                                                    </div>
                                                    <div className="statistics-info">
                                                        <h3>Tournament</h3>
                                                        <h4>{player?.overall?.gameType?.tournament}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <h2 className="h6">Stages</h2>
                                                <div className="statistics-card">
                                                    <div className="statistics-info">
                                                        <h3>Final</h3>
                                                        <h4>{player?.overall?.stages?.final}</h4>
                                                    </div>
                                                    <div className="statistics-info">
                                                        <h3>Semi final</h3>
                                                        <h4>{player?.overall?.stages?.semiFinal}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card-flex-content">
                                                    <h2>Match location</h2>
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>Home</h3>
                                                            <h4 className="text-center">{player?.overall?.matchLocation?.home}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Away</h3>
                                                            <h4>{player?.overall?.matchLocation?.away}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="team-statistics">
                                        <div className="row g-3">
                                            <div className="col-12 col-sm-6">
                                                <div className="statistics-card">
                                                    <div className="statistics-info">
                                                        <h3>Total Minutes Played</h3>
                                                        <h4>{player?.overall?.timePlayed}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="statistics-card">
                                                    <div className="statistics-info">
                                                        <h3>Total Goal Assists</h3>
                                                        <h4>{player?.overall?.assists}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <div className="statistics-card">
                                                    <div className="positions-stat statistics-info gap-3">
                                                        <h3 className="fw-bold fs-5">Positions</h3>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <h3>Attacker</h3>
                                                            <h4>{player?.overall?.positions?.attacker}</h4>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <h3>Defender</h3>
                                                            <h4>{player?.overall?.positions?.defender}</h4>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <h3>Goalkeeper</h3>
                                                            <h4>{player?.overall?.positions?.goalKeeper}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="statistics-card">
                                                    <div className="statistics-info">
                                                        <h3 className="fw-bold fs-5">Man of the match</h3>
                                                        <h4>{player?.overall?.playerOfTheMatch}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="statistics-card">
                                                    <div className="statistics-info">
                                                        <h3 className="fw-bold fs-5">Captain</h3>
                                                        <h4>{player?.overall?.captain}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card-flex-content">
                                                    <h2>Bookings</h2>
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>Yellow Card</h3>
                                                            <div className="match-card">
                                                                <div className="card-type yellow-card"></div>
                                                                <div className="match-card-count">{player?.overall?.bookings?.yellowCards}</div>
                                                            </div>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Red Card</h3>
                                                            <div className="match-card">
                                                                <div className="card-type red-card"></div>
                                                                <div className="match-card-count">{player?.overall?.bookings?.redCards}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="wrapper-card">
                            <div className="row g-3">
                                <div className="col-xl-6">
                                    <div className="team-statistics">
                                        <div className="bg-white-statistics">
                                            <div className="row g-3">
                                                <div className="col-12">
                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                        <h2>Total Goals Scored </h2>
                                                        <h3>{player?.overall?.totalGoalsScored}</h3>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <h2 className="h6 fw-bold">How Scored</h2>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="how-score-blk">
                                                        <div className="how-score-icon">
                                                            <img src='/assets/images/right-foot.png' alt="Foot" />
                                                        </div>
                                                        <div className="how-score-info">
                                                            <h3>{player?.overall?.howScored?.rightFootCount}</h3>
                                                            <p>Right foot</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="how-score-blk">
                                                        <div className="how-score-icon">
                                                            <img src='/assets/images/left-foot.png' alt="Foot" />
                                                        </div>
                                                        <div className="how-score-info">
                                                            <h3>{player?.overall?.howScored?.leftFootCount}</h3>
                                                            <p>Left foot</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="how-score-blk">
                                                        <div className="how-score-icon">
                                                            <img src='/assets/images/head.png' alt="Head" />
                                                        </div>
                                                        <div className="how-score-info">
                                                            <h3>{player?.overall?.howScored?.headedCount}</h3>
                                                            <p>Head</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="how-score-blk">
                                                        <div className="how-score-icon">
                                                            <img src='/assets/images/other.png' alt="By Other" />
                                                        </div>
                                                        <div className="how-score-info">
                                                            <h3>{player?.overall?.howScored?.otherCount}</h3>
                                                            <p>Other</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="team-statistics">
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                    <h2>Total Goals Conceded</h2>
                                                    <h3>{player?.overall?.totalGoalsConceded}</h3>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <h2 className="h6">How Conceded</h2>
                                                <div className="statistics-card">
                                                    <div className="statistics-info">
                                                        <h3>From Penalty</h3>
                                                        <h4>{player?.overall?.howConceded?.penalty}</h4>
                                                    </div>
                                                    <div className="statistics-info">
                                                        <h3>Free kick direct</h3>
                                                        <h4>{player?.overall?.howConceded?.directFreeKick}</h4>
                                                    </div>
                                                    <div className="statistics-info">
                                                        <h3>Open play</h3>
                                                        <h4>{player?.overall?.howConceded?.openPlay}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card-flex-content">
                                                    <h2>Where From</h2>
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>Inside the box</h3>
                                                            <h4 className="text-center">{player?.overall?.whereConceded?.insideTheBoxCount}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Outside the box</h3>
                                                            <h4>{player?.overall?.whereConceded?.outsideTheBoxCount}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="card-flex-content">
                                                    <h2>Goals Saved</h2>
                                                    <div className="statistics-card">
                                                        <div className="statistics-info">
                                                            <h3>Penalty</h3>
                                                            <h4 className="text-center">{player?.overall?.goalsSaved?.penalty}</h4>
                                                        </div>
                                                        <div className="statistics-info">
                                                            <h3>Open play</h3>
                                                            <h4>{player?.overall?.goalsSaved?.openPlay}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    )
}

export default PlayerDetail;